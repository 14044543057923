import React, { useEffect } from 'react';
import imagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";
import { Col, Container, Row } from "react-bootstrap";
import PortfolioItem from "../../../components/portfolio/PortfolioItemOne";
import portfolios from "../../../data/portfolio/brand-pharmacore.json";
import TeamParagonTitle from '../../../pages/career/TeamParagonTitle';


const PortfolioMasonry = ({ wide, gutter }) => {
    useEffect(() => {
        const grid = document.querySelector('.masonry-list');
        imagesLoaded(grid, () => {
            new Masonry(grid);
        });
    }, []);

    return (
        <div className="brook-team-area bg_color--1 pt--80 pt-sm--80 pt-md--80 slick-arrow-hover">
            <Container>
                <Row>
                    <Col xs={12}>
                        <TeamParagonTitle
                            wrapperClass={'text-center'}
                            heading={"OUR BRANDS"}
                            headingColor={"#215AA8"}
                        />
                    </Col>
                </Row>
            </Container>
            <Container fluid={!!wide} className={wide ? 'px-0' : ''}>
                <Row>
                    <Col lg={12}>
                        <div className="masonry-list portfolio-grid-metro4 pt--80">
                            <div className="resizer" />
                            {portfolios.map(portfolio => (
                                <div
                                    className={`grid-masonry-item portfolio-33-33 ${!gutter ? 'px-0 mb-0' : ''}`}
                                    key={portfolio.id}
                                >
                                    <PortfolioItem
                                        title={portfolio.title}
                                        thumb={require('../../../assets/img/portfolio/pharmacore/' + portfolio.thumb)}
                                        desc={portfolio.desc}
                                        link={portfolio.link}
                                        linkTitle={portfolio.linkTitle}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default PortfolioMasonry;
