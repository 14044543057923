import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Parallax, {Layer} from "react-parallax-scroll";
import { useMediaQuery } from 'react-responsive';

const BrandBannerItem = ({brandName, brandImage}) => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1200px)'})
    return (
        <Parallax>
            <Layer className={"checkpoint bg-banner-"+brandName} style={isBigScreen ? {height:"45vh"} : {height:"50vh"}} settings={{speed: -0.2, type: 'backgroundY'}}>
                <div id={brandName} className="brook-contact-form-area" style={{height:"100%"}}>
                    <Container style={{height:"100%", position:"relative"}}>
                        <Row style={{position: "absolute", width:"100%", top:"50%", transform:"translateY(-50%)"}}>
                            <Col lg={12} className="wow move-up text-center">
                                <div>
                                    <img src={brandImage} alt={brandName}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layer>
        </Parallax>
    );
};

export default BrandBannerItem;
