import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import { useMediaQuery } from 'react-responsive'

const NewsItem = ({data, className, type}) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    const [showedDate,setShowedDate] = useState(data.dateCreated)

    useEffect(() => {
        var date = parseInt(data.dateCreated.substring(8,10))
        var month = parseInt(data.dateCreated.substring(5,7))
        var year = parseInt(data.dateCreated.substring(0,4))
        setShowedDate(dateFormat(new Date(year, month-1, date), "mmm dd, yyyy"))
    }, [data.dateCreated])
    return (
        <div className={`blog-grid blog-grid--modern blog-standard ${className}`}>
            <div className="post-thumb">
                <Link to={ type === 'article' ? `${process.env.PUBLIC_URL + '/post/article/'+data.id}` : `${process.env.PUBLIC_URL + '/post/news/'+data.id}`}>
                    <img src={data.image} alt={data.name}/>
                </Link>
            </div>
            <div className="post-content text-center">
                <div className="post-inner">
                    <div className="post-meta mb--10">
                        <div className="post-date" style={{fontFamily:"Montserrat"}}>{showedDate}</div>
                    </div>
                    <h5 className="heading heading-h5 line-height-1-39" style={isMobile ? {fontFamily:"Montserrat"} : {fontFamily:"Montserrat", minHeight:"14vh"}}>
                        <Link to={ type === 'article' ? `${process.env.PUBLIC_URL + '/post/article/'+data.id}` : `${process.env.PUBLIC_URL + '/post/news/'+data.id}`}>{data.name}</Link>
                    </h5>
                    <Link to={ type === 'article' ? `${process.env.PUBLIC_URL + '/post/article/'+data.id}` : `${process.env.PUBLIC_URL + '/post/news/'+data.id}`} className="post-read-more" style={{color: "white"}}>&nbsp;</Link>
                </div>
            </div>
        </div>
    );
};

NewsItem.propTypes = {
    data: PropTypes.object
};

export default NewsItem;
