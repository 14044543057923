import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './transition.scss';
import App from './App';
import qoreContext from './qoreContext';

ReactDOM.render(
    <qoreContext.context.Provider
        value={{
            client: qoreContext.client
        }}
    >
        <App/>
    </qoreContext.context.Provider>
, document.getElementById('root'));
