import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player/youtube'
import TeamMemberTwo from '../team-member/elements/TeamMemberTwo';

export default function PharmacorePartParagon() {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1441px)' })
    return (
        <>
            <div className="brook-testimonial-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--14">
                <Container style={isBigScreen ? { maxWidth: "1591px" } : null}>
                    <h3 className="heading wow move-up text-center" style={{ color: "#1f5aa8", fontFamily: "Montserrat" }}>
                        <img src={require('../../assets/img/logo/paragoncorp og.png')} alt="thumb" width={350} />
                    </h3>

                    <Row className='pt--80'>
                        <Col lg={6}>
                            <ReactPlayer
                                url='https://www.youtube.com/watch?v=7ZPcoEhONCA&ab_channel=ParagonTechnologyandInnovation'
                                width={isBigScreen ? "100%" : null}
                                style={{ paddingBottom: "30px" }}
                                controls={true}
                                className="wow move-up"
                            />

                            <h3 className="heading wow move-up mb-3" style={{ fontFamily: "Montserrat", color: "#215AA8" }}>
                                OUR CORE VALUES
                            </h3>
                            <TeamMemberTwo />
                        </Col>
                        <Col lg={6} className={isBigScreen ? "pl-5" : null}>
                            <p className='wow move-up'>
                                Established in 1985, ParagonCorp is a holding company that was initiated to provide beauty products through the biggest and most loved beauty brands such as Wardah, Emina, Kahf, Crystallure, and Instaperfect with the purpose of creating a greater good to the society.
                            </p><br></br>
                            <span className='wow move-up'><b>VISION</b></span>
                            <p className='wow move-up'>A company commited to having the best corporate governance and continuous improvement, in order to make each day better than yesterday, through high quality products that benefit the Paragonians, partners society and the environment.
                            </p><br></br>
                            <span className='wow move-up'><b>MISSION</b></span>
                            <ul className='wow move-up'>
                                <li>Developing Paragonians</li>
                                <li>Creating Kindness for Customer</li>
                                <li>Continuous Improvement</li>
                                <li>Grow Together</li>
                                <li>Preserving the Earth</li>
                                <li>Supporting Education and Health of the Nation</li>
                                <li>Developing Business</li>
                            </ul>
                        </Col>
                    </Row>
                    <div className="call-btn text-center mt--60 wow move-up">
                        <a href="https://paragon-innovation.com" target="__blank" rel="noreferrer" className='brook-btn bk-btn-paragon btn-xs-size btn-rounded'>
                            <span>ParagonCorp Website &nbsp;</span>
                            <i className="fa fa-arrow-right"/>
                        </a>
                    </div>
                </Container>
            </div>
        </>
    )
}
