import React from 'react';
import {Link} from "react-router-dom";
import {Row, Col} from 'react-bootstrap';
import Copyright from "../../components/footer/Copyright";

import instagram from "../../assets/img/instagram_white.png"
import linkedin from "../../assets/img/linkedin_white.png"
import facebook from "../../assets/img/facebook_white.png"

import { useMediaQuery } from 'react-responsive'

const FooterParagon = () => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <footer
            style={{backgroundColor:"#215AA8"}}
            className="page-footer bg_color--3 pl--150 pr--150 pl_lp--70 pr_lp--70 pl_lg--70 pr_lg--70 pl_md--50 pr_md--50 pl_sm--30 pr_sm--30">

            <div className="copyright pb--50 pt--100">
                <Row className="align-items-center">
                    {!isMobile && <Col xs={12} md={isBigScreen ? 4 : 5}>
                        <div className="copyright-left text-md-left text-center" style={{color:"white"}}>
                            <Copyright isBigScreen={isBigScreen}/>
                        </div>
                    </Col>}
                    <Col xs={12} md={4}>
                        <div className="copyright-left text-md-left text-center" style={{display: "flex", justifyContent: "center"}}>
                            <ul className="bk-copyright-menu d-flex bk-hover justify-content-center justify-content-md-start flex-wrap flex-sm-nowrap">
                                {/* <li className="mr--10"><Link to={process.env.PUBLIC_URL + "/privacy-policy"}><p style={{fontFamily:"Montserrat", textAlign: "center"}}><strong>Privacy Policy</strong></p></Link></li>
                                <li className="mr--10"><Link to={process.env.PUBLIC_URL + "/terms-conditions"}><p style={{fontFamily:"Montserrat", textAlign: "center"}}><strong>Terms &amp; Conditions</strong></p></Link></li> */}
                                <li className="mr--10"><Link to={process.env.PUBLIC_URL + "/contact"}><p style={{fontFamily:"Montserrat", textAlign: "center"}}><strong>Contact &amp; Connect</strong></p></Link></li>
                            </ul>
                        </div>
                    </Col>

                    <Col xs={12} md={isBigScreen ? 4 : 3}>
                        <div style={isBigScreen ? {textAlign: "right"} : {textAlign: "center"}}>
                            <p style={{color:"white"}}>
                                <strong style={isBigScreen ? null : isMobile ? null : {fontSize: "14px"}}>Follow us on</strong>
                                {isMobile && <br/>}
                                <a target="_blank" rel="noopener noreferrer" href={"https://www.instagram.com/paragoncorp.my/"}><img style={!isBigScreen ? {height:"48px"} : null} className={isMobile ? "ptb--10" : isBigScreen ? "ml--20  ptb--10" : "ml--10  ptb--10"} src={instagram} alt="ig"/></a>
                                <a target="_blank" rel="noopener noreferrer" href={"https://www.linkedin.com/company/paragoncorpmalaysia/"}><img  style={!isBigScreen ? {height:"48px"} : null} className={isMobile || isBigScreen ? "ml--20  ptb--10" : "ml--10  ptb--10"} src={linkedin} alt="in"/></a>
                                <a target="_blank" rel="noopener noreferrer" href={"https://www.facebook.com/para.gon.562"}> <img  style={!isBigScreen ? {height:"48px"} : null} className={isMobile || isBigScreen ? "ml--20  ptb--10" : "ml--10  ptb--10"} src={facebook} alt="fb"/></a>
                            </p>
                        </div>
                    </Col>

                    {isMobile && <Col xs={12} md={5}>
                        <div className="copyright-left text-md-left text-center" style={{color:"white"}}>
                            <Copyright/>
                        </div>
                    </Col>}
                </Row>
            </div>
        </footer>
    );
};

export default FooterParagon;