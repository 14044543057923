import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";

import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import ParagonHomeSlider from "../../container/slider/ParagonHomeSlider";
import Footer from "../../container/footer/FooterParagon";
import About from "../../container/about-us/AboutParagon";
import InstagramFeedOne from '../../container/instagram-feed/InstagramFeedOne';
import TestimonialThree from '../../container/testimonial/elements/TestimonialThree';
import PortfolioMasonry from '../../container/portfolio/pages/PortfolioMasonry';
import PharmacoreCarrerOpportunity from '../../container/service/PharmacoreCarrerOpportunity';
import PharmacorePartParagon from '../../container/service/PharmacorePartParagon';
import TeamParagon from '../career/TeamParagon';

const HomeParagonWebsite = () => {
    return (
        <Fragment>
            <Helmet>
                <title>{`Pharmacore Technology and Innovation`}</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2" revealFooter={false}>
                <Header />
                <ParagonHomeSlider />
                <About />
                <PharmacorePartParagon />
                <PortfolioMasonry wide={true} gutter={false} />
                <TeamParagon />
                <TestimonialThree />
                <PharmacoreCarrerOpportunity />
                <InstagramFeedOne />
                <Footer />
            </LayoutDefault>
        </Fragment>
    );
};

export default HomeParagonWebsite;