import React, { useEffect, useState } from 'react';
import Instagram from "../../components/instagram";
import axios from 'axios';
import TeamParagonTitle from '../../pages/career/TeamParagonTitle';
import { Container } from 'react-bootstrap';

const InstagramFeedOne = () => {

    const igAccessToken = process.env.REACT_APP_IG_TOKEN
    const igUrlAPI = process.env.REACT_APP_IG_URL_API
    const fullUrl = igUrlAPI + igAccessToken

    const [dataIG, setDataIG] = useState([])

    useEffect(() => {
        axios.get(fullUrl)
            .then(function (response) {
                // handle success
                setDataIG(response.data.data.slice(0, 6))
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }, [fullUrl]);

    function InstagramPharmacore() {
        if (dataIG.length) {
            return (
                dataIG.map(instagram => (
                    <Instagram key={instagram.id} data={instagram} variant="grid-style--1" />
                ))
            )

        } else {
            return (
                <p>Geting instagram pharmacore.my data</p>
            )

        }
    }

    return (
        <>
            <div className="brook-instagram-area pt--80 pt-md--80 pt-sm--60 bg_color--6">
                <TeamParagonTitle
                    wrapperClass={'text-center'}
                    heading={"LATEST NEWS"}
                    headingColor={"#215AA8"}
                />
                <div className="brook-instagram-area ptb--80 ptb-md--80 ptb-sm--60 bg_color--6">
                    <div className="bk-instagram-list instagram-grid">
                        <div className="instagram-grid-wrap instagram-grid-5 pl--30 pr--30">
                            {<InstagramPharmacore />}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default InstagramFeedOne;