import React from 'react';
import {Container} from "react-bootstrap";
import SidebarLayout from "../news/NewsLayoutWithSidebar";
import WithOutSidebarLayout from "../news/NewsLayoutWithoutSidebar";

const NewsContainer = ({sidebar, sidebarPosition, pagination, data, type}) => {
    return (
        <div className="bk-blog-grid-area bg_color--5 section-ptb-150">
            <Container>
                {sidebar ? (
                    <SidebarLayout type={type} data={data} pagination={pagination} sidebarPosition={sidebarPosition}/>
                ) : (
                    <WithOutSidebarLayout type={type} pagination={pagination} data={data}/>
                )}
            </Container>
        </div>
    );
};

export default NewsContainer;
