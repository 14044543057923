import React from 'react';
import qoreContext from '../../qoreContext';

const Navigation = ({after, before}) => {
    const {data:articleAfter} = qoreContext.view("allNews").useGetRow(after)
    const {data:articleBefore} = qoreContext.view("allNews").useGetRow(before)
    return (
        <div className="post-nav-list mb--45 wow move-up">
            <div className="nav-item previous">
                <a href={`${process.env.PUBLIC_URL + "/post/news/"+before}`}>
                    <div className="link-text">
                        <span className="fa fa-arrow-left"/>
                        <p>Prev</p>
                    </div>
                    <span>{articleBefore?.name}</span>
                </a>
            </div>
            <div className="nav-item next mt_sm--30">
                <a href={`${process.env.PUBLIC_URL + "/post/news/"+after}`}>
                    <div className="link-text">
                        <p>Next</p>
                        <span className="fa fa-arrow-right"/>
                    </div>
                    <span>{articleAfter?.name}</span>
                </a>
            </div>
        </div>
    );
};

export default Navigation;
