import React from 'react';
import { useMediaQuery } from 'react-responsive';

const PortfolioItemOne = ({ className, thumb, title, desc, link, linkTitle }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const is1440 = useMediaQuery({ query: '(max-width: 119em' })
    return (
        <div className={`portfolio portfolio_style--1 ${className}`}>
            <div className="thumb">
                <img src={thumb} alt={title} />
            </div>
            <div className="port-overlay-info" style={{ background: "#dee9f3" }}>
                <div className="hover-action">
                    <h1 style={{ color: "#1f5aa8", fontFamily: "Montserrat", size: "9px" }} className="post-overlay-title">
                       {title}
                    </h1>
                    <p className={isMobile ? "p-2" : is1440 ? "p-4" : "p-5"} style={{ color: "#1f5aa8" }}>{desc}</p>
                    <div className="blog-btn view-more-btn heading-color font-700 wow move-up">
                        <a href={link} target="__blank" rel="noreferrer">
                            <span style={{ color:  "#1f5aa8"}}>Go to {linkTitle}</span>
                            <span className="btn-arrow" style={{ color: "#1f5aa8"}} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioItemOne;
