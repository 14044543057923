import React from 'react';
import { Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

const TeamMemberItemTwo = ({ data }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1441px)' })

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleMouseOut = (e) => {
        console.log('oit');
        e.preventDefault()
        setShow(false);
    }
    const handleShow = (e) => {
        e.preventDefault()
        setShow(true);
    }

    return (
        <>
            <div className="team team__style--2">
                <div className="thumb">
                    <img
                        className="wow move-up"
                        src={require('../../assets/img/team/team-2/' + data.thumb)}
                        alt={data.name}
                        //onMouseLeave={(e) => handleMouseOut(e)}
                        onMouseEnter={(e) => handleShow(e)}
                    />
                </div>
                <p className='text-center heading wow move-up' style={isBigScreen ? null : { marginBottom: "40px" }}>{data.coreValue}</p>
            </div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title style={{ paddingLeft: "32px", paddingTop: "32px", color: "#215AA8" }}>{data.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "32px" }}>{data.designation}</Modal.Body>
            </Modal>
        </>

    );
};

export default TeamMemberItemTwo;