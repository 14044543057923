import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import FooterParagon from "../../container/footer/FooterParagon";
import NewsDetailsContent from './NewsDetailsContent';
import qoreContext from '../../qoreContext';
import { useParams } from 'react-router';
import BeatLoader from "react-spinners/BeatLoader";

const BlogDetailsNoSidebar = () => {
    const [titleHeader, setTitleHeader] = React.useState('News - Paragon')
    var {data: news} = qoreContext.view("allNews").useGetRow(useParams().id);
    var {data: article} = qoreContext.view("allArticle").useGetRow(useParams().id);

    React.useEffect(() => {
        if(news){
            setTitleHeader(news.name+" - News - Paragon")
        } else if (article){
            setTitleHeader(article.name+" - Article - Paragon")
        }
    }, [news, article]);
    

    return (
        <Fragment>
            <Helmet>
                <title>{titleHeader}</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-1 bg_color--1" revealFooter={false}>
                <Header isBlock={true}/>

                
                {news ?
                    <NewsDetailsContent type={"news"} content={news} contentBefore={news.beforeId} contentAfter={news.nextId} sidebar={false}/> : 
                    article ?
                    <NewsDetailsContent type={"article"} content={article} contentBefore={article.beforeId} contentAfter={article.nextId} sidebar={false}/> :
                    <div style={{minHeight:"70vh", width:"100%", textAlign:"center"}}>
                        <div style={{marginTop:"30vh"}}/>
                        <BeatLoader color={"#215AA8"} loading={true} size={72}  />
                    </div>
                }
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default BlogDetailsNoSidebar;
