import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import News from "./NewsContainer"
import FooterParagon from "../../container/footer/FooterParagon"
import qoreContext from '../../qoreContext';

const NewsPage = () => {
    const {data} = qoreContext.view("allArticle").useListRow({
        isActive: true,
        '$by.dateCreated': 'desc',
    });
    return (
        <Fragment>
            <Helmet>
                <title>Paragon News</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2">
                <Header/>
                <Breadcrumb
                    title={"ARTICLE"}
                    bgImage={"bg-image-innovation"}
                />
                <News
                    type='article'
                    data={data}
                    sidebar={false}
                    pagination={false}
                />
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default NewsPage;
