import React from 'react';
import TypedText from "../../../components/typed-text";
import { useMediaQuery } from 'react-responsive'

const AboutContentOne = ({title, heading, bottomText, bottomTextName, content, contentSecond, contentThird}) => {
    const isBigScreen = useMediaQuery({query: '(min-width: 1441px)'})
    const isMobile = useMediaQuery({query: '(max-width: 767px)'})
    return (
        <div className="content pl--5" style={isBigScreen ? {paddingLeft: "80px"} : null}>
            <div className="title--paragon wow move-up">
                <p className="heading mb-3" style={{color:"#215AA8"}}>{title}</p>
                <h2 style={{fontWeight: "500 !important", fontFamily: "Montserrat", color:"#215AA8", fontSize:"28px"}}>
                    {heading}
                    <br/>
                    <p className="cd-words-wrapper theme-color" style={{color: "#6B89FF", fontSize:"22px"}}><TypedText
                        content={['Since 2011']}
                        typeSpeed={100}
                        backSpeed={100}
                        loop={true}
                    />
                    </p>
                </h2>
            </div>

            <p className="wow move-up" style={isMobile ? {color:"#215AA8", fontWeight:"bold", lineHeight:"1.2", marginTop:"24px"} : {color:"#215AA8", fontWeight:"bold", marginTop: "50px"}}>{content}</p>
            <p className="wow move-up" style={isMobile ? {color:"#215AA8", lineHeight:"1.2"} : {color:"#215AA8"}}>{contentSecond}</p>
            <p className="wow move-up" style={isMobile ? {color:"#215AA8", fontWeight:"", lineHeight:"1.2", marginTop:"24px"} : {color:"#215AA8", fontWeight:""}}>{contentThird}</p>
            <p className="wow move-up" style={{color:"#215AA8", fontWeight:"bold", marginBottom:"24px"}}>{bottomText}</p>
            <p className="wow move-up" style={{color:"#215AA8", marginTop:"0px"}}>{bottomTextName}</p>
            {/* <img src={require('../../../assets/img/pharmacore remove bg.png')} alt="thumb" width={170}/> */}
        </div>
    );
};

export default AboutContentOne;
