import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Article from "./Article";
import Navigation from "./Navigation";

const NewsDetailsContent = ({sidebar, content, contentBefore, contentAfter, type}) => {
    return (
        <div className="brook-blog-details-area bg_color--1 pt--90 pb--150">
            <Container>
                <Row>
                    <Col lg={10} className={!sidebar ? 'mx-auto' : null}>
                        <div className="blog-details-wrapper">
                            <Article content={content}/>
                            {type === "article" ? null : 
                            <Navigation after={contentAfter} before={contentBefore}/>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NewsDetailsContent;
