import React from 'react';
import {Container} from "react-bootstrap";
import SidebarLayout from "./NewsLayoutWithSidebar";
import WithOutSidebarLayout from "./NewsLayoutWithoutSidebar";

const NewsContainer = ({sidebar, sidebarPosition, pagination, data}) => {
    return (
        <div className="bk-blog-grid-area bg_color--5 section-ptb-150">
            <Container>
                {sidebar ? (
                    <SidebarLayout data={data} pagination={pagination} sidebarPosition={sidebarPosition}/>
                ) : (
                    <WithOutSidebarLayout pagination={pagination} data={data}/>
                )}
            </Container>
        </div>
    );
};

export default NewsContainer;
