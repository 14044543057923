import React from 'react';
import { useMediaQuery } from 'react-responsive'

const TestimonialItemOne = ({data, classes}) => {

    const isMobile = useMediaQuery({query: '(max-width: 767px)'})

    return (
        <div className={`testimonial testimonial_style--1 ${classes && classes}`} style={isMobile ? null : { height : "430px" }}>
            <div className="content">
                <p className="bk_pra heading-font" style={{ color: "black" }}>
                    “{data.quote}”
                </p>
                <div className="testimonial-info">
                    <div className="post-thumbnail">
                        <img width={100} src={require('../../assets/img/testimonial/pharmacore/' + data.client.photo)} alt={data.client.name}/>
                    </div>
                    <div className="client-info" >
                        <h6 style={{ color: "black" }}>{data.client.name}</h6>
                        <span style={{ color: "black" }}>{data.client.designation}</span>
                    </div>
                </div>
                <div className="testimonial-quote">
                    <span className="fa fa-quote-right" style={{ color: "#1f5aa8" }}/>
                </div>
            </div>
        </div>
    );
};

export default TestimonialItemOne;