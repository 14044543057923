import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import ParagonItemBrand from './ParagonItemBrand';

const ParagonFourBrand = () => {
    return (
        <div className="bk-number-speaks bk-about-area-paragon section-ptb-100">
            <Container>
                <Row>
                    <Col lg={12} className={'mt_md--40 mt_sm--40'} style={{display: "flex", alignItems: "center", minHeight:"30vh"}}>
                        <div className="speakers-number-wrap brand-default counter-grid-paragon" >
                            <ParagonItemBrand
                                link="#wardah"
                                image="logo_wardah_colored.png"
                                altText="Wardah Logo"
                            />
                            <ParagonItemBrand
                                link="#makeover"
                                image="logo_makeover_colored.png"
                                altText="Make Over Logo"
                            />
                            <ParagonItemBrand
                                link="#emina"
                                image="logo_emina_colored.png"
                                altText="Emina Logo"
                            />
                            <ParagonItemBrand
                                link="#kahf"
                                image="logo_kahf_temp.png"
                                altText="Kahf Logo"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParagonFourBrand;