import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import CallContentVertical from "../../../components/call-to-action/CallContentVertical";

const CallToActionVerticalTwo = ({ bgColor, bgImage, text, btnLink }) => {
    return (
        <div className="brook-call-to-action ptb--120 ptb-md--80 ptb-sm--60"
            style={{
                backgroundColor: bgColor, 
                backgroundImage: `url(${bgImage})`,
                //backgroundColor: 'rgba(33,90,168,.9)',
                backgroundBlendMode: 'overlay',
                //backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left',
                //backgroundOrigin : 'border-box',
                //backgroundSize: 'auto'
            }}

        >
            <Container>
                <Row className="align-items-center">
                    <Col xs={12}>
                        <CallContentVertical
                            heading={text}
                            headingColor="#215AA8"
                            btnText="Apply now"
                            btnLink={btnLink}
                            btnClasses="bk-btn-paragon btn-sm-size btn-rounded"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CallToActionVerticalTwo;