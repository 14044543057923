import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrollToTop from "./helper/scrollToTop";
import WOW from "wowjs";

import Error from "./pages/others/Error";
import HomeParagonWebsite from './pages/homes/HomeParagonWebsite';
import AboutParagon from "./pages/about/AboutParagon";
import AboutBrandParagon from "./pages/about/AboutBrandParagon";
import SearchResult from "./pages/search/SearchResult";
import Innovation from "./pages/innovation/Innovation"
import CareerPage from './pages/career/CareerPage';
import CSRPage from './pages/csr/CSRPage';
import ScholarshipPage from './pages/scholarship/ScholarshipPage';
import ScholarshipDetailPage from './pages/scholarship/ScholarshipDetailPage'
import LegalInformationPage from './pages/legal-information/LegalInformationPage';
import NewsPage from "./pages/news/NewsPage";
import TermsConditions from "./pages/terms-conditions/TermsConditionsPage";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicyPage";
import NewsDetailsWithoutSidebar from "./pages/news/NewsDetailsWithoutSidebar"
import ArticlePage from "./pages/article/ArticlePage"
import ContactConnect from "./pages/contact-connect/ContactConnect"
import TempNewsPage from './pages/news/TempNewsPage';
import TempArticlePage from './pages/article/TempArticlePage'
// import ReactGa from "react-ga"

const App = () => {

    // useEffect(() => {
    //     ReactGa.initialize('UA-195242118-1')
    //     ReactGa.pageview(window.location.pathname + window.location.search)
    // }, [])
    useEffect(() => {
        new WOW.WOW().init();
    }, []);

    const redirectPIS = () => {
        window.location.href = "https://summit.paragon-innovation.com";
          return (
            <div>
              <header className="App-header">
              </header>
            </div>
          );
    }
    return (
        <Router>
            <ScrollToTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + '/innovation-summit/doing-good-challenge'}`}
                        component={() => {
                            redirectPIS();
                                return (
                                    <div>
                                    <header className="App-header">
                                    </header>
                                    </div>
                                )
                        }}/>
                    <Route path={`${process.env.PUBLIC_URL + '/summit'}`}
                        component={() => {
                            redirectPIS();
                                return (
                                    <div>
                                    <header className="App-header">
                                    </header>
                                    </div>
                                )
                        }}/>
                    <Route
                        path={`${process.env.PUBLIC_URL + '/contact'}`}
                        component={ContactConnect}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/terms-conditions'}`}
                        component={TermsConditions}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/privacy-policy'}`}
                        component={PrivacyPolicy}
                    />
                    <Route
                        exact={true}
                        path={`${process.env.PUBLIC_URL + '/post/:type/:id'}`}
                        component={NewsDetailsWithoutSidebar}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/article'}`}
                        component={ArticlePage}
                    />
                    
                    <Route
                        path={`${process.env.PUBLIC_URL + '/article-temp'}`}
                        component={TempArticlePage}
                    />
                    
                    <Route
                        path={`${process.env.PUBLIC_URL + '/news'}`}
                        component={NewsPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/news-temp'}`}
                        component={TempNewsPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/brand'}`}
                        component={AboutBrandParagon}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/about-us'}`}
                        component={AboutParagon}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/result'}`}
                        component={SearchResult}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/innovation'}`}
                        component={Innovation}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/career'}`}
                        component={CareerPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/csr'}`}
                        component={CSRPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/scholarship/type/:scholarshipType'}`}
                        component={ScholarshipDetailPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/scholarship'}`}
                        component={ScholarshipPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + '/legal'}`}
                        component={LegalInformationPage}
                    />
                    <Route
                        exact={true} path={`${process.env.PUBLIC_URL + '/'}`}
                        component={HomeParagonWebsite}
                    />
                    <Route exact component={Error}/>
                </Switch>
            </ScrollToTop>
        </Router>
    );
};

export default App;