import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import ContactInfo from "../../container/contact/ContactInfoOne";
import FooterParagon from "../../container/footer/FooterParagon";

const ContactConnect = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Contact Us - Pharmacore</title>
            </Helmet>

            <LayoutDefault className="template-color-1 template-font-2">
                <Header/>
                <Breadcrumb
                    title="CONTACT"
                    bgImage={"bg-image-contact"}
                />
                <ContactInfo/>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default ContactConnect;