import React from 'react';
import { useMediaQuery } from 'react-responsive';

const Instagram = ({ data, variant }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 1200px)' })
    const is1440 = useMediaQuery({ query: '(max-width: 1500px)' })
    return (
        <div className={`item-grid ${variant}`}>
            <div className="thumb" style={is1440 ? { 'maxHeight' : '220px' } : { 'maxHeight': '300px' }} >
                <a href={data.permalink} target="__blank" rel="noreferrer">
                    {
                        data.media_type === 'IMAGE' ||
                            data.media_type === 'CAROUSEL_ALBUM'
                            ? <img
                                src={data.media_url}
                                alt={data.id}
                                style={isMobile ? { "width": '100%' } : is1440 ? { "width": '100%', "height": "220px" } : { "width": '100%', "height": "300px" }} />
                            : <video style={is1440 ? { "width": '100%'} : {"width": '100%'}}>
                                <source src={data.media_url} />
                            </video>
                    }
                </a>
                {/* <div className="item-info">
                    <div className="inner">
                        <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fa fa-heart"/>{data.likes}
                        </a>
                        <a href={process.env.PUBLIC_URL + "/"}>
                            <i className="fa fa-comments"/>{data.comments}
                        </a>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Instagram;