import React from 'react';
import { Container } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import SliderSwiper from "../../components/swiper";
import sliderData from "../../data/slider/ParagonHomeSlider.json";

const ParagonHomeSlider = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1441px)' })

    return (
        <div className="hero-slider hero-slider--paragon">
            <SliderSwiper style={{ float: "left" }}>
                {sliderData.map(slide => (
                    <div className="swiper-slide" key={slide.id}>
                        <div
                            className="slide-inner slide-bg-image"
                            style={{ backgroundImage: `url(${require('../../assets/img/' + slide.bgImg)})`, backgroundColor: 'rgba(33,90,168,.5)', backgroundBlendMode: 'overlay' }}
                        >
                            <Container>
                                <div
                                    className={`slide-content`}
                                >
                                    <div data-swiper-parallax="300" className="slide-title">
                                        <h2 style={{fontFamily: "Montserrat"}}>{slide.heading}</h2>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                ))}
            </SliderSwiper>
        </div>
    );
};


export default ParagonHomeSlider;
