import React from 'react';
import HeaderContainer from "../../container/header/HeaderContainerOne";
import { Container } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

const HeaderParagon = ({ isBlock }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1441px)' })
    return (
        <HeaderContainer
            logoVersion={'light'}
            transparent={isBlock ? false : true}
            sticky={true}
        >

            <Container style={isBigScreen ? { maxWidth: "1591px" } : null}>
                <div className="heading wow move-up" style={{ color: "#1f5aa8", fontFamily: "Montserrat" }}>
                    <a href="/">
                        <img src={require('../../assets/img/logo/pharmacore-paragon-white.png')} alt="thumb" width={230} />
                    </a>
                </div>
            </Container>

            {/* <div className="header-right pr_md--30 pr_sm--30 flex-20">
                <HeaderConfig/>
            </div> */}
        </HeaderContainer>
    );
};

export default HeaderParagon;