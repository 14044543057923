import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CallToActionVerticalTwo from '../call-to-action/elements/CallToActionVerticalTwo'
import TeamParagonTitle from '../../pages/career/TeamParagonTitle';
import banner1 from "../../assets/img/banner/officerup-new.jpg"
import banner2 from "../../assets/img/banner/operational-new.png"

export default function PharmacoreCarrerOpportunity() {
    return (
        <div className="brook-team-area bg_color--1 pt--80 pt-sm--80 pt-md--80 slick-arrow-hover">
            <Container>
                <Row>
                    <Col xs={12}>
                        <TeamParagonTitle
                            wrapperClass={'text-center'}
                            heading={"CAREER OPPORTUNITY"}
                            headingColor={"#215AA8"}
                        />
                    </Col>
                </Row>
            </Container>
            <Row className='mt--80'>
                <Col lg={6} className={'p-0'}>
                    <CallToActionVerticalTwo bgColor='#dee9f3' bgImage={banner1} text='Officer & Executive' btnLink='https://docs.google.com/forms/d/e/1FAIpQLSeNTo7AP_Ec847g8r-kFNU5-FP3JOZtmHVNV6PjZ4Dq_u-xFA/viewform?usp=sharing' />
                </Col>
                <Col lg={6} className={'p-0'}>
                    <CallToActionVerticalTwo bgColor='#efefef' bgImage={banner2} text='Operational' btnLink='https://bit.ly/JobOperational2023' />
                </Col>
            </Row>
        </div>
    )
}
