import React, {useEffect, useState} from 'react';
import Thumbnail from "../../components/about-us/thumbnail/AboutThumbOne";
import dateFormat from 'dateformat'

const Article = ({content}) => {
    const [showedDate,setShowedDate] = useState(content.dateCreated)

    useEffect(() => {
        var date = parseInt(content.dateCreated.substring(8,10))
        var month = parseInt(content.dateCreated.substring(5,7))
        var year = parseInt(content.dateCreated.substring(0,4))
        setShowedDate(dateFormat(new Date(year, month-1, date), "mmm dd, yyyy"))
    }, [content.dateCreated])
    return (
        <article className="blog-post standard-post">
            <header className="header mb--40 text-center">
                <h3 style={{fontFamily:"Montserrat"}} className="heading heading-h3 font-32">
                    {content.name}
                </h3>
                <div className="post-meta mt--20">
                    <div style={{fontFamily:"Montserrat"}} className="post-date">{showedDate}</div>
                </div>
            </header>

            <Thumbnail thumb={content.image} className="mb--10"/>
            <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--60" dangerouslySetInnerHTML={{__html: content.caption}} ></p>
            <section className="content basic-dark2-line-1px pb--50 mb--35">
                <div className="inner">
                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph1}} ></p>
                    </div>

                    {content.image1 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image1} alt="image_1"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption1}} ></p>

                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph2}} ></p>
                    </div>

                    {content.image2 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image2} alt="image_2"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption2}} ></p>

                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph3}} ></p>
                    </div>

                    {content.image3 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image3} alt="image_3"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption3}} ></p>

                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph4}} ></p>
                    </div>

                    {content.image4 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image4} alt="image_4"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption4}} ></p>

                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph5}} ></p>
                    </div>

                    {content.image5 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image5} alt="image_5"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption5}} ></p>

                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph6}} ></p>
                    </div>

                    {content.image6 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image6} alt="image_6"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption6}} ></p>

                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph7}} ></p>
                    </div>

                    {content.image7 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image7} alt="image_7"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption7}} ></p>

                    <div className="desc mt--45 mb--50 news">
                        <p style={{fontFamily:"Montserrat", fontSize:"18px"}} className="bk_pra wow move-up" dangerouslySetInnerHTML={{__html: content.paragraph8}} ></p>
                    </div>

                    {content.image8 ? 
                    <div className="desc mt--45 mb--10 news" style={{justifyContent:"center", display: "flex", alignItems: "flex-start"}}>
                        <img src={content.image8} alt="image_8"/>
                    </div> : null}
                    <p style={{fontFamily:"Montserrat", fontSize:"12px", textAlign: "center"}} className="bk_pra wow move-up mb--50" dangerouslySetInnerHTML={{__html: content.caption8}} ></p>

                </div>
            </section>
        </article>
    );
};

export default Article;
