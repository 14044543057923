import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import AboutContentOne from "../../components/about-us/content/AboutContentParagon";
import { useMediaQuery } from 'react-responsive'

const AboutParagon = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1441px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const is1440 = useMediaQuery({ query: '(max-width: 119em' })
    return (
        <div className="bk-about-area-paragon section-ptb-150" style={isMobile ? null : { backgroundColor: "#215AA8" }}>
            <Container style={isBigScreen ? { maxWidth: "1591px" } : null}>
                <Row>
                    <Col lg={6}>
                        {/*  <Container style={isBigScreen ? { backgroundColor: "white", padding: "48px", borderRadius: "10px", maxWidth: "1591px", height: "600px"} : isMobile ? { padding: "0px" } : { backgroundColor: "white", padding: "48px", borderRadius: "10px" }}> */}
                        <Container style={isMobile ? { padding: "0px" } : { backgroundColor: "white", borderRadius: "10px", height: "600px", padding: "48px" }}>
                            <AboutContentOne
                                title={'Introduction to Pharmacore'}
                                heading={"Pharmacore Technology and Innovation, Sdn. Bhd"}
                                typingHeading={"Since 1985"}
                                //content={"With more than 37 years of experience,"}
                                contentSecond={"Having the purpose of creating a greater good for the society through innovation, ParagonCorp was established in 1985. Starting as the holding company based in Indonesia, we have now authorized a subsidiary company in Malaysia named Pharmacore Technology and Innovation, Sdn. Bhd."}
                                contentThird={"As of now, Pharmacore Technology and Innovation operates as a principal where we market ParagonCorp's leading brands, such as Wardah, Emina, Kahf, Instaperfect, and Crystallure."}
                            />
                        </Container>
                    </Col>

                    <Col lg={6}>
                        {/* <Container style={isBigScreen ? { backgroundColor: "white", padding: "48px", borderRadius: "10px", maxWidth: "1591px" } : isMobile ? { padding: "0px" } : { backgroundColor: "white", padding: "48px", borderRadius: "10px" }}> */}
                        <Container style={isMobile ? { padding: "0px" } : { backgroundColor: "white", borderRadius: "10px", height: "600px", padding: "48px" }}>
                            <div className={`thumb text-left wow move-up`}>
                                {is1440 ? <img src={require('../../assets/img/pharmacore team.jpg')} alt="thumb" /> : isTabletOrMobile ? <img src={require('../../assets/img/pharmacore team.png')} alt="thumb" /> : <img src={require('../../assets/img/pharmacore team.png')} alt="thumb" />}
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>


        </div>
    );
};

export default AboutParagon;
