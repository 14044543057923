import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import BrandList from "../../container/counter/ParagonFourBrand";
import BrandBannerItem from "../../container/contact/BrandBannerItem";
import BrandDetailItem from "../../container/contact/BrandDetailItem";
import LayoutDefault from "../../layouts/LayoutDefault";
import FooterParagon from "../../container/footer/FooterParagon"
import wardah from '../../assets/img/wardah_logo_big.png';
import makeover from '../../assets/img/make_over_logo_big.png';
import emina from '../../assets/img/emina_logo_big.png';
import kahf from '../../assets/img/kahf_logo_big.png';

const AboutBrandParagon = () => {

    return (
        <Fragment>
            <Helmet>
                <title>Brand Paragon</title>
            </Helmet>
            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealFooter={false} >
                <Header/>
                <Breadcrumb
                    title={"BRANDS"}
                    bgImage={"bg-image-brand"}
                />
                <BrandList/>
            </LayoutDefault>

            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                
                <BrandBannerItem
                    brandName="wardah"
                    brandImage={wardah}/>
            </LayoutDefault>
            <BrandDetailItem
                    brandWebsiteLink="https://www.wardahbeauty.com/"
                    brandText="Wardah is a pioneer of halal cosmetics in Indonesia that combines natural purity with a halal, modern, and reliable process to produce high-quality halal products with international innovation standards. Through love, respect and appreciation, we are committed to getting women love themselves and making true beauty shines."/>
            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                <BrandBannerItem
                    brandName="makeover"
                    brandImage={makeover}/>
            </LayoutDefault>
            <BrandDetailItem 
                    brandWebsiteLink="https://www.makeoverforall.com/"
                    brandText="There is no limit in defining your beauty for Make Over, because beauty belongs to all women. Set yourself free and show the world that freedom and expression are worldwide communication. It's time to live in a world where only beauty dominates us all."/>
            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                <BrandBannerItem
                    brandName="emina"
                    brandImage={emina}/>
            </LayoutDefault>
            <BrandDetailItem
                    brandWebsiteLink="https://www.eminacosmetics.com/"
                    brandText="For Emina, every day is a new opportunity to be greeted with enthusiasm and passion. Emina's range of skincare and make-up are very easy to apply for teenagers and new makeup users. Emina will help you  radiate positive energy every day with happy and healthy skin!"/>
            <LayoutDefault className="template-color-4 template-font-1 bg_color--1" revealSection={true}>
                <BrandBannerItem
                    brandName="kahf"
                    brandImage={kahf}/>
            </LayoutDefault>
            <BrandDetailItem
                    brandWebsiteLink="https://www.kahfeveryday.com/"
                    brandText="Kahf is a halal grooming brand for men and inspired by nature with natural, halal, and dermatologically tested ingredients. Men self-care products which prepare each user to discover and have positive impact on their surroundings from the very start of the day."/>
            <LayoutDefault className="template-color-1 template-font-2" revealFooter={false}>
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default AboutBrandParagon;