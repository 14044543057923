import React from 'react';

const PrivacyPolicyContent = () => {
    return (
        <>
            <h3 className="text-center mt--40 mb--40" style={{fontFamily:"Montserrat"}}>Privacy Policy</h3>
            <p>
                This Privacy Policy explains how PT Paragon Technology and Innovation handles the personal data we collect online (for example through our social media, our website or brand trading websites) and offline (e.g. data collection at retail locations and direct promotional activities) which hereinafter referred to as "Service"
                <br/>
                <br/>
                This Privacy Policy describes the types of personal data we collect and process, how we can use and share that data, and the choices available to you regarding our handling of your data.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                What We Collect
            </h4>
            <h4 style={{fontFamily:"Montserrat"}}>
                Information we collect directly from you
            </h4>
            <p>
                We collect information that you provide directly to us, such as when you enter an account on our website, our brand trading website, register for our newsletter, what is meant by interest in our vouchers, or it can be said on social media. The types of personal data we may collect directly from you include:
            </p>
            <ul>
                <li>
                    Contact information, such as your name, date of birth, email address, mobile number, Instagram account and profession;
                </li>
                <li>
                    Address, such as street name, area, city, state, and country.
                </li>
                <li>
                    Skin information, such as your skin type, skin problem, and skin tone;
                </li>
                <li>
                    Product information, such as the make-up products you have and how you can find out about our products;
                </li>
                <li>
                    Any other information you choose to provide.
                </li>
            </ul>
            <br/>
            <h4 style={{fontFamily:"Montserrat"}}>
                Information about your use of our Services
            </h4>
            <p>
                We collect information about your use of the Service, such as: buying our products on our official brand trading website, or products that interest you
                <br/>
                <br/>
                If you buy one of our products from our official Brand Commerce, we collect your purchase order data, such as: what products were purchased, the number of products, the total price of the order, and the vouchers you used in the transaction; we may also collect your personal data such as your name, email and telephone number; and your address as the information required to deliver your order.
                <br/>
                <br/>
                If you use our sharing feature to inform your friends about the product, we will be able to collect that information.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Information we collect from other sources
            </h4>
            <p>
                When you access the service through a social network, we collect information from the social network in accordance with your settings on the social network. If you wish us to be on social media, we will collect information about these interactions. The information we collect includes your name, image, social media username, and email address that corresponds to the social media. We may also collect information about you from third parties, including publicly available sources or friends who share our products on their sharing channels.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Information we collect in an automated way
            </h4>
            <p>
                When you visit our site, we collect certain information automatically. To collect this information, we may use cookies and similar technologies. "Cookies" are text files that a website sends to a visitor's computer or other device connected to the internet to identify the visitor or to store information or settings in the browser. We may also collect information about your online activities from time to time and on third party websites. The information we collect automatically may include:
                <br/>
                <br/>
                <ul>
                    <li>
                        URL that describes a visitor to our website;
                    </li>
                    <li>
                        The search terms used to reach our website;
                    </li>
                    <li>
                        Details about the devices used to access our website (such as: IP address, browser information, device information and operating system information);
                    </li>
                    <li>
                        Details about your interactions with our website (such as: date, time, length of time you accessed our site, specific pages accessed during your visit to our website, and email marketing that you opened); and
                    </li>
                    <li>
                        Usage information (such as: the number and frequency of visitors to our website).
                    </li>
                </ul>
                <br/>
                We may associate this information with your newsletter if you enter, the device you use to connect to our services, and the email or social media account you use to connect with us.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Third Party Services
            </h4>
            <p>
                In general, the third party providers we use will only collect, use and disclose your information to the extent necessary to enable them to perform the services they provide to us.
                <br/>
                <br/>
                However, certain third party service providers (eg Xendit) have their own privacy policies with respect to the information we must provide to them for transactions related to your purchases. For these providers, we recommend that you read their privacy policy so that you can understand the way in which your personal information will be handled by this provider.
                <br/>
                <br/>
                We also work with other Shipping providers and Logistics Partners, who have their own privacy policies with respect to the information we need to make available for your shipping related transactions. After you leave our store website or are directed to a third party website or application, you are no longer governed by this Privacy Policy or our website Terms of Service.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Advertising and Analytics Services Provided by Third Parties
            </h4>
            <p>
                We may allow others to provide analytics services and serve ads on our behalf on the internet and in mobile applications. These entities may use cookies and other technologies to collect information about your use of the Service and other websites and applications, for example: IP address, web browser, mobile network information, pages viewed, pages, time spent on the page or in applications, and the links accessed. This information can be used by us; and their use but not limited to analyzing and tracking data, but also to determine the popularity of certain products or content, deliver advertisements and content targeted to your interests on our Services and other websites, and have a better understanding of your online activities.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                How We Use Your Information
            </h4>
            <p>
                We may use the information we collect to deliver the products you buy on our official brand trading site, improve your experience while browsing our website, customize your subscription newsletters and also our interactions with you, and to provide, maintain and improve our services. 
                <br/>
                <br/>
                We may also use the information we collect to:
                <ul>
                    <li>
                        Communicate with you about our Services, including to notify you of products and services that may be of interest to you;
                    </li>
                    <li>
                        Facilitating your engagement with the Services, including enabling you to post product comments and reviews;
                    </li>
                    <li>
                        Notify you of our new product launch dates, new product information and brand related information;
                    </li>
                    <li>
                        Provide promotional offers and giveaway; and
                    </li>
                    <li>
                        Conduct or administer surveys and other market research.
                    </li>
                </ul>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                How We Can Share Your Information
            </h4>
            <p>
                We may share the information we collect with:
                <br/>
                <br/>
                <ul>
                    <li>
                        With selected partners as part of a co-sponsored promotion;
                    </li>
                    <li>
                        To comply with a legal or regulatory obligation, protect and defend our rights or property, protect the safety of our customers and users of our website or the public, and protect against legal liability;
                    </li>
                    <li>
                        In connection with, or during negotiation, a merger, sale of company assets, financing, or acquisition of all or part of our business by another company;
                    </li>
                    <li>
                        between and between our current and future affiliates, subsidiaries and other companies under the same control and ownership; and
                    </li>
                </ul>
                <br/>
                When you provide product reviews or other content, that content will be sorted by our team and the selected product reviews will be posted publicly. Other website visitors may be able to see your name or other information about you that you post, such as your social media accounts or username.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Payment Information
            </h4>
            <p>
                If you buy our products from our official brand commerce site, to complete an order, you must make a payment through our integrated payment method. We work with Xendit, a payment gateway provider, which will collect your payment information according to the method you choose. All your data will be encrypted via the Payment Card Industry Data Security Standard (PCI-DSS).
                <br/>
                <br/>
                All direct payment gateways comply with the standards set by the PCI-DSS administered by the PCI Security Standards Council, which is a joint effort of brands such as Visa, MasterCard, American Express and Discover. And Xendit has met this standard. The PCI-DSS requirements help ensure the safe handling of credit card information by our authorized Brand Commerce stores and their service providers. For more information, you may also want to read the Xendit Terms of Service <a style={{color:"#215AA8"}} href="https://www.xendit.co/en/terms-and-conditions/" target="_blank" rel="noopener noreferrer">here</a> or the Privacy Statement <a style={{color:"#215AA8"}} href="https://www.xendit.co/en/privacy-policy/" target="_blank" rel="noopener noreferrer">here</a>.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Your Rights
            </h4>
            <h4 style={{fontFamily:"Montserrat"}}>
                Opting Out of the Newsletter
            </h4>
            <p>
                You can unsubscribe from our newsletter at any time by following the instructions included in the email. If you choose not to receive such communications, please note that we may continue to send you non-promotional emails (such as emails about changes in legal provisions or our privacy policy).
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Limiting Cookies
            </h4>
            <p>
                Most web browsers are set to accept cookies by default. You can usually choose to set your browser to delete or reject browser cookies. Please note that if you choose to delete or reject cookies this may affect the availability and functionality of our website.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Children
            </h4>
            <p>
                Our services are not designed for children and do not knowingly collect personal data from children (under 12 years). If you have reason to believe that a child has provided personal data to us, please contact us at marketing@pti-cosmetics.com and we will double-check the facts and delete the information from our database if it is proven to be child data.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Links to Other Websites and Third Party Content
            </h4>
            <p>
                We may provide links to third party websites, services and applications, such as Instagram and other trading websites, which are not operated or controlled by us. This Privacy Policy does not apply to third party services, and we cannot be responsible for the content, privacy policies, or practices of third party services. We encourage you to review the privacy policies of any third party services before providing any information to or through them. Your interactions with features from these third parties are governed by the privacy policies of the third party services that provide these features.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Data Retention
            </h4>
            <p>
                Our retention periods for personal data are based on business needs and legal requirements. We retain personal data for as long as necessary for the purposes of the processing for which the data was collected, and other related purposes that are permitted.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Changes to Our Privacy Policy
            </h4>
            <p>
                We may change this Privacy Policy from time to time. If we do, we will post the updated policy on our site and will indicate when the Privacy Policy was last revised. We will also send you an email notification that our Privacy Policy has recently changed. You should periodically review our current Privacy Policy to stay informed about our personal data practices.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                Contact Us
            </h4>
            <p>
                If you have any questions or concerns regarding this Privacy Policy, or would like to update our preferences or information about you, please contact us at the address provided below. We will take reasonable and prompt steps to accommodate your request.
                <br/>
                <br/>
            </p>
            <h4 style={{fontFamily:"Montserrat"}}>
                PT Paragon Technology and Innovation
            </h4>
            <p>
            Jalan Swadarma Raya Kampung Baru IV No. 1 Jakarta - 12250 Indonesia
            <br/>
            <br/>
            E-mail: marketing@pti-cosmetics.com
            <br/>
            <br/>
            Phone:
            <br/>
            +62 21 584 9070, +62 21 584 9077,
            <br/>
            +62 21 585 3883, +62 21 585 2494
            <br/>
            <br/>
            Fax: +62 21 585 2439
            </p>
        </>
    )
}

export default PrivacyPolicyContent;