import React,{Fragment} from 'react';
import {Helmet} from "react-helmet";
import LayoutDefault from "../../layouts/LayoutDefault";
import Header from "../../components/header/HeaderParagon";
import Breadcrumb from "../../components/breadcrumb/BreadcrumbTitleOnly";
import News from "./NewsContainer"
import FooterParagon from "../../container/footer/FooterParagon"
import qoreContext from '../../qoreContext';
import BeatLoader from "react-spinners/BeatLoader";

const TempNewsPage = () => {
    const {data, status} = qoreContext.view("allNews").useListRow({
        '$by.dateCreated': 'desc'
    });
    
    return (
        <Fragment>
            <Helmet>
                <title>Paragon News</title>
            </Helmet>
            <LayoutDefault className="template-color-1 template-font-2">
                <Header/>
                <Breadcrumb
                    title={"NEWS"}
                    bgImage={"bg-image-career"}
                />
                
                {status === "success" ? 
                    <News
                        data={data}
                        sidebar={false}
                        pagination={false}
                    />
                :
                    <div style={{minHeight:"70vh", width:"100%", textAlign:"center"}}>
                        <div style={{marginTop:"30vh"}}/>
                        <BeatLoader color={"#215AA8"} loading={true} size={72}  />
                    </div>
                }
                <FooterParagon/>
            </LayoutDefault>
        </Fragment>
    );
};

export default TempNewsPage;
